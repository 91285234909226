import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

const Golfer = ({ golfer }) => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("");

    const COLUMNS = [
        {
            title: "",
            accessor: "field",
            className: "table__column--field",
        },
        {
            title:
                "Thursday" +
                (golfer?.rounds[0]["roundComplete"] === false ? "*" : ""),
            accessor: "round1",
            className: "table__column--points",
        },
        {
            title:
                "Friday" +
                (golfer?.rounds[1]["roundComplete"] === false ? "*" : ""),
            accessor: "round2",
            className: "table__column--points",
        },
        {
            title:
                "Saturday" +
                (golfer?.rounds[2]["roundComplete"] === false ? "*" : ""),
            accessor: "round3",
            className: "table__column--points",
        },
        {
            title:
                "Sunday" +
                (golfer?.rounds[3]["roundComplete"] === false ? "*" : ""),
            accessor: "round4",
            className: "table__column--points",
        },
    ];

    useEffect(() => {
        let golferStatus = golfer?.status;
        let golferPosition = golfer?.position;
        setStatus(
            golferStatus?.toLowerCase() === "cut" ||
                golferStatus?.toLowerCase() === "wd" ||
                golferPosition?.toLowerCase() === "cut"
                ? "out"
                : ""
        );

        let rounds = golfer?.rounds;
        if (rounds !== null) {
            let updatedData = [];

            let totalPoints = {
                field: "Total",
                round1: rounds[0]["totalPoints"],
                round2: rounds[1]["totalPoints"],
                round3: rounds[2]["totalPoints"],
                round4: rounds[3]["totalPoints"],
            };

            let scoreToPar = {
                field: "Score to Par",
                round1: rounds[0]["scoreToPar"],
                round2: rounds[1]["scoreToPar"],
                round3: rounds[2]["scoreToPar"],
                round4: rounds[3]["scoreToPar"],
            };

            let roundPlace = {
                field: "Best Score/round",
                round1: rounds[0]["roundPlace"]
                    ? rounds[0]["roundPlace"]["points"] +
                      " (" +
                      rounds[0]["roundPlace"]["place"] +
                      ")"
                    : null,
                round2: rounds[1]["roundPlace"]
                    ? rounds[1]["roundPlace"]["points"] +
                      " (" +
                      rounds[1]["roundPlace"]["place"] +
                      ")"
                    : null,
                round3: rounds[2]["roundPlace"]
                    ? rounds[2]["roundPlace"]["points"] +
                      " (" +
                      rounds[2]["roundPlace"]["place"] +
                      ")"
                    : null,
                round4: rounds[3]["roundPlace"]
                    ? rounds[3]["roundPlace"]["points"] +
                      " (" +
                      rounds[3]["roundPlace"]["place"] +
                      ")"
                    : null,
            };

            let holeInOnes = {
                field: "Hole in Ones",
                round1: rounds[0]["holeInOnes"],
                round2: rounds[1]["holeInOnes"],
                round3: rounds[2]["holeInOnes"],
                round4: rounds[3]["holeInOnes"],
            };

            updatedData.push(totalPoints, scoreToPar, roundPlace, holeInOnes);

            setData(updatedData);
        }
    }, [golfer]);

    return (
        <div className={`golfer ${status === "out" ? "golfer__out" : ""}`}>
            <div className="total_rounds_points">{golfer.pointsFromRounds}</div>
            <div className={`golfer__name`}>
                {golfer.firstName} {golfer.lastName}
            </div>

            <div className={`golfer__table`}>
                <Table>
                    <thead>
                        <tr>
                            {COLUMNS.map((c) => {
                                return (
                                    <th
                                        key={c.accessor}
                                        className="table__header"
                                        style={{
                                            minWidth:
                                                c.accessor === "field"
                                                    ? 100
                                                    : "",
                                        }}
                                    >
                                        {c.title}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((r) => {
                            return (
                                <tr
                                    className={`${
                                        r["field"] === "Total"
                                            ? "table__row--total"
                                            : ""
                                    }`}
                                >
                                    {COLUMNS.map((c) => {
                                        return (
                                            <td
                                                className={`table__cell ${c.className}`}
                                            >
                                                {r[c.accessor]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
export default Golfer;
