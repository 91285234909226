import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import Golfers from "./Golfers/Golfers";
import Index from "./Index/Index";
import Lineups from "./Lineups/Lineups";
import Scoreboard from "./Scoreboard/Scoreboard";
import Scores from "./Scores/Scores";
import "../../styles/main-view.scss";
import { ajax } from "jquery";
import { retrieveGolfData } from "../../queries";

const MainView = () => {
    const clientHeight = window.innerHeight;
    const headerHeight = 52;
    const tabHeight = 41;

    const [viewHeight, setViewHeight] = useState(
        clientHeight - headerHeight - tabHeight
    );

    const [players, setPlayers] = useState(null);
    // const [allGolfers, setAllGolfers] = useState(null);
    const [pickedGolfers, setPickedGolfers] = useState(null);
    const [mappedPickedGolfers, setMappedPickedGolfers] = useState(null);
    const [leaderboard, setLeaderboard] = useState(null);
    const [lastUpdated, setLastUpdated] = useState("unavailable");
    const [pot, setPot] = useState(null);
    const [tournament, setTournament] = useState(null);

    const golfData = useCallback(() => {
        retrieveGolfData().then((data) => {
            setPlayers(data.players);
            setPickedGolfers(data.pickedGolfers);
            mapPickedGolfers(data.pickedGolfers);
            setLeaderboard(data.leaderboard);
            setLastUpdated(data.pool.lastUpdated);
            setPot(data.pool.pot);
            setTournament(data.tournament);
        });
    }, []);

    const mapPickedGolfers = (picked) => {
        let updatedMappedPickedGolfers = new Map();

        picked.forEach((golfer) => {
            updatedMappedPickedGolfers.set(golfer.golferId, golfer);
        });

        setMappedPickedGolfers(updatedMappedPickedGolfers);
    };

    const getWindowHeight = () => {
        return window.innerHeight;
    };

    useEffect(() => {
        golfData();
    }, [golfData]);

    useEffect(() => {
        const handleResize = () => {
            setViewHeight(getWindowHeight() - headerHeight - tabHeight);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Row>
            <Col className="main-view">
                <Row>
                    <Col className="last-updated">
                        LAST UPDATED: {new Date(lastUpdated).toLocaleString()}{" "}
                        (CT)
                    </Col>
                </Row>
                <Row className="welcome">
                    <Col>WELCOME TO THE US OPEN 2024</Col>
                </Row>
                <Row>
                    <Col className="tabs">
                        <Tabs fill justify>
                            <Tab eventKey="index" title="Index" className="tab">
                                <Index
                                    leaderboard={leaderboard}
                                    height={viewHeight}
                                    tournament={tournament}
                                    pot={pot}
                                />
                            </Tab>
                            <Tab eventKey="scoreboard" title="Scoreboard">
                                <Scoreboard
                                    players={players}
                                    height={viewHeight}
                                />
                            </Tab>
                            <Tab
                                eventKey="golfers"
                                title="Golfers"
                                className="tab"
                            >
                                <Golfers
                                    golfers={pickedGolfers}
                                    height={viewHeight}
                                />
                            </Tab>
                            <Tab
                                eventKey="scores"
                                title="Scores"
                                className="tab"
                            >
                                <Scores players={players} height={viewHeight} />
                            </Tab>
                            <Tab eventKey="lineups" title="Lineups">
                                <Lineups
                                    height={viewHeight}
                                    players={players}
                                    mappedGolfers={mappedPickedGolfers}
                                />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default MainView;
