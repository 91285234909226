import React from "react";
import "../../../styles/scores.scss";
import Score from "./Score";

const Scores = ({ players, height }) => {
    return (
        <div style={{ height: height }}>
            <div className="scores__scores">
                {players?.map((player) => {
                    return <Score player={player} />;
                })}
            </div>
        </div>
    );
};

export default Scores;
