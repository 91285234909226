const Pot = ({ pot }) => {
    return (
        <div className="pot">
            <div>$$$</div>
            <div className="pot__places">
                <div>1st</div>
                <div>2nd</div>
                <div>3rd</div>
            </div>
            <div className="pot__distribution">
                <div>${Math.floor(pot?.totalPot * 0.55)}</div>
                <div>${Math.floor(pot?.totalPot * 0.3)}</div>
                <div>${Math.floor(pot?.totalPot * 0.15)}</div>
            </div>
        </div>
    );
};

export default Pot;
