import React from "react";
import "../../../styles/index.scss";
import Tournament from "./Tournament";
import ScoringKey from "./ScoringKey";
import TournamentStatus from "./TournamentStatus";
import Pot from "./Pot";

const Index = ({ leaderboard, height, tournament, pot }) => {
    return (
        <div className="index__index">
            <Tournament leaderboard={leaderboard} height={height} />
            <div>
                <ScoringKey height={height} />
            </div>
            <div className="index__column3">
                <TournamentStatus tournament={tournament} />
                <Pot pot={pot} />
            </div>
        </div>
    );
};
export default Index;
