const TournamentStatus = ({ tournament }) => {
    return (
        <div className="tournament-status">
            <div className="tournament-status-row">
                <span className="tournament-status-row__day">
                    Opening Round
                </span>
                <span className="tournament-status-row__status">
                    {tournament?.roundStatus[0]}
                </span>
            </div>
            <div className="tournament-status-row">
                <span className="tournament-status-row__day">Cut Day</span>
                <span className="tournament-status-row__status">
                    {tournament?.roundStatus[1]}
                </span>
            </div>
            <div className="tournament-status-row">
                <span className="tournament-status-row__day">Moving Day</span>
                <span className="tournament-status-row__status">
                    {tournament?.roundStatus[2]}
                </span>
            </div>
            <div className="tournament-status-row">
                <span className="tournament-status-row__day">Final Round</span>
                <span className="tournament-status-row__status">
                    {tournament?.roundStatus[3]}
                </span>
            </div>
        </div>
    );
};

export default TournamentStatus;
