import React from "react";
import { Row, Col } from "react-bootstrap";
import Lineup from "./Lineup";
import "../../../styles/lineups.scss";
import Statistics from "../Statistics/Statistics";

const Lineups = ({ height, players, mappedGolfers }) => {
    return (
        <Row>
            <Col className="lineups" style={{ height: height }}>
                <div className="lineups__picks">
                    {players?.map((player, index) => {
                        return <Lineup number={index + 1} player={player} />;
                    })}
                </div>
            </Col>
            <Col
                xs={3}
                className="lineups__statistics"
                style={{ height: height }}
            >
                <Statistics players={players} mappedGolfers={mappedGolfers} />
            </Col>
        </Row>
    );
};

export default Lineups;
