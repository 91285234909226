import { Table } from "react-bootstrap";

const ScoringKey = ({ height }) => {
    const COLUMNS = [
        {
            title: "Score",
            accessor: "score",
        },
        {
            title: "Player's Score",
            accessor: "playersScore",
        },
    ];

    const DATA = [
        {
            score: "Score to Par",
            playersScore: "x",
        },
        {
            score: "Hole in Ones",
            playersScore: "-3",
        },
        {
            score: "All 4 rounds under 70",
            playersScore: "-4",
        },
        {
            score: "Make Cut",
            playersScore: "-3",
        },
        {
            score: "Miss Cut",
            playersScore: "+5",
        },
        {
            score: "Best Score/day",
            playersScore: "-7",
        },
        {
            score: "2nd Best/day",
            playersScore: "-5",
        },
        {
            score: "3rd/day",
            playersScore: "-3",
        },
        {
            score: "4th/day",
            playersScore: "-2",
        },
        {
            score: "5th/day",
            playersScore: "-1",
        },
        {
            score: "1st place/tournament",
            playersScore: "-25",
        },
        {
            score: "2nd place/tournament",
            playersScore: "-21",
        },
        {
            score: "3rd place/tournament",
            playersScore: "-18",
        },
        {
            score: "4th place/tournament",
            playersScore: "-15",
        },
        {
            score: "5th place/tournament",
            playersScore: "-12",
        },
        {
            score: "6th place/tournament",
            playersScore: "-10",
        },
        {
            score: "7th place/tournament",
            playersScore: "-9",
        },
        {
            score: "8th place/tournament",
            playersScore: "-8",
        },
        {
            score: "9th place/tournament",
            playersScore: "-7",
        },
        {
            score: "10th place/tournament",
            playersScore: "-6",
        },
    ];

    return (
        <div style={{ height: height - 40 }} className="scoring-key">
            <div className="scoring-key__title">Scoring Key</div>
            <div>
                <Table className="scoring-key-table">
                    <thead>
                        <tr>
                            {COLUMNS.map((c) => {
                                return (
                                    <th
                                        key={c.accessor}
                                        className="scoring-key-table__cell"
                                    >
                                        {c.title}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {DATA.map((r) => {
                            return (
                                <tr>
                                    {COLUMNS.map((c) => {
                                        return (
                                            <td className="scoring-key-table__cell">
                                                {r[c.accessor]}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
export default ScoringKey;
