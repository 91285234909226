import { useEffect, useState } from "react";
import { OverlayTrigger, Table } from "react-bootstrap";
import "../../../styles/tournament.scss";

const Leaderboard = ({ height, leaderboard }) => {
    const [leaderboardData, setLeaderboardData] = useState([]);

    const COLUMNS = [
        {
            title: "#",
            accessor: "position",
        },
        {
            title: "Golfer",
            accessor: "fullName",
        },
        {
            title: "Thru",
            accessor: "thru",
        },
        {
            title: "Round Score",
            accessor: "currentRoundScore",
        },
        {
            title: "Total Score to Par",
            accessor: "total",
        },
        {
            title: "Status",
            accessor: "status",
        },
    ];

    useEffect(() => {
        if (leaderboard !== null) {
            let updatedLeaderboardData = [];

            leaderboard["leaderboardRows"].forEach((golfer) => {
                golfer["fullName"] = golfer.firstName + " " + golfer.lastName;

                updatedLeaderboardData.push(golfer);
            });

            setLeaderboardData(updatedLeaderboardData);
        }
    }, [leaderboard]);

    return (
        <div
            style={{ height: height - 40 }}
            className="tournament__leaderboard"
        >
            <div className="leaderboard__title">TOURNAMENT LEADERBOARD</div>
            <div>
                <Table className="leaderboard__table">
                    <thead>
                        <tr>
                            {COLUMNS.map((c) => {
                                return <th>{c.title}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.map((r) => {
                            return (
                                <tr>
                                    {COLUMNS.map((c) => {
                                        return <td>{r[c.accessor]}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default Leaderboard;
