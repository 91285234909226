import React, { useEffect, useState } from "react";
import "../../../styles/statistics.scss";
import { Table } from "react-bootstrap";

const Statistics = ({ players, mappedGolfers }) => {
    const COLUMNS = [
        {
            title: "Name",
            accessor: "name",
        },
        {
            title: "# of teams",
            accessor: "count",
        },
    ];

    const [playersData, setPlayersData] = useState([]);

    useEffect(() => {
        if (players !== null && mappedGolfers !== null) {
            let playersCount = new Map();

            players.forEach((player) => {
                player.golferIds.forEach((id) => {
                    if (!playersCount.has(id)) {
                        playersCount.set(id, 1);
                    } else {
                        playersCount.set(id, playersCount.get(id) + 1);
                    }
                });
            });
            let updatedPlayersData = [];

            playersCount.forEach((value, key) => {
                let golferCount = {
                    name:
                        mappedGolfers.get(key).firstName +
                        " " +
                        mappedGolfers.get(key).lastName,
                    count: value,
                };
                updatedPlayersData.push(golferCount);
            });

            updatedPlayersData.sort((a, b) => b.count - a.count);

            setPlayersData(updatedPlayersData);
        }
    }, [players, mappedGolfers]);

    return (
        <div className="statistics__statistics">
            <div className="statistics__title">Frequency</div>
            <div>
                <Table className="statistics-table">
                    <thead>
                        <tr>
                            {COLUMNS.map((c) => {
                                return <th>{c.title}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {playersData.map((r) => {
                            return (
                                <tr>
                                    {COLUMNS.map((c) => {
                                        return <td>{r[c.accessor]}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
export default Statistics;
