const PlayerGolfers = ({ golfers }) => {
    return (
        <>
            {golfers.map((golfer) => {
                return (
                    <div className="player-golfers-golfer">
                        <div className="player-golfers-golfer__name">
                            {golfer.firstName + " " + golfer.lastName}
                        </div>
                        <div className="player-golfers-golfer__total-points">
                            {golfer.totalPoints}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default PlayerGolfers;
