import "../../../styles/lineups.scss";

const Lineup = ({ number, player }) => {
    return (
        <div className="pick">
            <div className="pick__number">{number}</div>

            <div className="pick__name">
                {player.firstName} {player.lastName}
            </div>
            <div className="pick__golfers">
                {player.golfers?.map((golfer) => {
                    return (
                        <div>
                            <span
                                className={`{pick__golfer ${
                                    golfer.status.toLowerCase() === "cut" ||
                                    golfer.status.toLowerCase() === "wd" ||
                                    golfer.position.toLowerCase() === "cut"
                                        ? "pick__out"
                                        : ""
                                }`}
                            >
                                {golfer.firstName} {golfer.lastName}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default Lineup;
