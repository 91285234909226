import React, { useEffect, useState } from "react";
import Golfer from "./Golfer";
import "../../../styles/golfers.scss";

const Golfers = ({ golfers, height }) => {
    const [sortedGolfers, setSortedGolfers] = useState(null);

    useEffect(() => {
        if (golfers != null) {
            let sorted = golfers.sort((a, b) => a.totalPoints - b.totalPoints);

            setSortedGolfers(sorted);
        }
    }, [golfers]);

    return (
        <div style={{ height: height }}>
            <div className="golfers__golfers">
                {sortedGolfers?.map((golfer) => {
                    return <Golfer golfer={golfer} />;
                })}
            </div>
        </div>
    );
};

export default Golfers;
