import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "../../../styles/scores.scss";

const Score = ({ player }) => {
    const [data, setData] = useState([]);
    const [combinedTotal, setCombinedTotal] = useState(0);

    const COLUMNS = [
        {
            title: "",
            accessor: "field",
            className: "table__column--field",
        },
        {
            title:
                player.golfers[0].firstName + " " + player.golfers[0].lastName,
            accessor: "golfer1",
            className: "table__column--golfer",
        },
        {
            title:
                player.golfers[1].firstName + " " + player.golfers[1].lastName,
            accessor: "golfer2",
        },
        {
            title:
                player.golfers[2].firstName + " " + player.golfers[2].lastName,
            accessor: "golfer3",
        },
        {
            title:
                player.golfers[3].firstName + " " + player.golfers[3].lastName,
            accessor: "golfer4",
        },
        {
            title:
                player.golfers[4].firstName + " " + player.golfers[4].lastName,
            accessor: "golfer5",
        },
    ];

    useEffect(() => {
        if (player !== null) {
            let updatedData = [];

            let combined = {
                field: "Combined Total",
                golfer1: player.golfers[0].totalPoints,
                golfer2: player.golfers[1].totalPoints,
                golfer3: player.golfers[2].totalPoints,
                golfer4: player.golfers[3].totalPoints,
                golfer5: player.golfers[4].totalPoints,
            };

            let totalPoints = {
                field: "Points from Rounds",
                golfer1: player.golfers[0].pointsFromRounds,
                golfer2: player.golfers[1].pointsFromRounds,
                golfer3: player.golfers[2].pointsFromRounds,
                golfer4: player.golfers[3].pointsFromRounds,
                golfer5: player.golfers[4].pointsFromRounds,
            };

            let makeMissCut = {
                field: "Make/Miss Cut?",
                golfer1: player.golfers[0]["hasMadeCut"],
                golfer2: player.golfers[1]["hasMadeCut"],
                golfer3: player.golfers[2]["hasMadeCut"],
                golfer4: player.golfers[3]["hasMadeCut"],
                golfer5: player.golfers[4]["hasMadeCut"],
            };
            let allRoundsUnder70 = {
                field: "4 Rounds under 70",
                golfer1: player.golfers[0]["allRoundsUnder70"],
                golfer2: player.golfers[1]["allRoundsUnder70"],
                golfer3: player.golfers[2]["allRoundsUnder70"],
                golfer4: player.golfers[3]["allRoundsUnder70"],
                golfer5: player.golfers[4]["allRoundsUnder70"],
            };

            let finalPlace = {
                field: "Final Place",
                golfer1:
                    player.golfers[0]["finalPlace"] != null
                        ? player.golfers[0]["finalPlace"]["points"] +
                          " (" +
                          player.golfers[0]["finalPlace"]["place"] +
                          ")"
                        : null,
                golfer2:
                    player.golfers[1]["finalPlace"] != null
                        ? player.golfers[1]["finalPlace"]["points"] +
                          " (" +
                          player.golfers[1]["finalPlace"]["place"] +
                          ")"
                        : null,
                golfer3:
                    player.golfers[2]["finalPlace"] != null
                        ? player.golfers[2]["finalPlace"]["points"] +
                          " (" +
                          player.golfers[2]["finalPlace"]["place"] +
                          ")"
                        : null,
                golfer4:
                    player.golfers[3]["finalPlace"] != null
                        ? player.golfers[3]["finalPlace"]["points"] +
                          " (" +
                          player.golfers[3]["finalPlace"]["place"] +
                          ")"
                        : null,
                golfer5:
                    player.golfers[4]["finalPlace"] != null
                        ? player.golfers[4]["finalPlace"]["points"] +
                          " (" +
                          player.golfers[4]["finalPlace"]["place"] +
                          ")"
                        : null,
            };

            updatedData.push(
                combined,
                totalPoints,
                makeMissCut,
                allRoundsUnder70,
                finalPlace
            );

            setData(updatedData);
            setCombinedTotal(
                player.golfers[0].totalPoints +
                    player.golfers[1].totalPoints +
                    player.golfers[2].totalPoints +
                    player.golfers[3].totalPoints +
                    player.golfers[4].totalPoints
            );
        }
    }, [player]);

    return (
        <div className={`score`}>
            <div className={`score__player`}>
                <div className={`player__name`}>
                    {player.firstName} {player.lastName}
                </div>
                <div className={`player__grand-total`}>
                    <div className={`grand-total__points`}>{combinedTotal}</div>
                </div>
            </div>
            <div className={`score__score`}>
                <div>
                    <Table className={`score__table`}>
                        <thead>
                            <tr style={{ alignItems: "center" }}>
                                {COLUMNS.map((c) => {
                                    return (
                                        <th
                                            key={c.accessor}
                                            className={`table__header`}
                                            style={{
                                                maxWidth:
                                                    c.accessor === "field"
                                                        ? 70
                                                        : 100,
                                                minWidth:
                                                    c.accessor === "field"
                                                        ? 120
                                                        : 70,
                                            }}
                                        >
                                            {c.title}
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((r) => {
                                return (
                                    <tr
                                        className={`${
                                            r["field"] === "Combined Total"
                                                ? "table__row--combined-total"
                                                : "table__row--field"
                                        }`}
                                    >
                                        {COLUMNS.map((c) => {
                                            return (
                                                <td
                                                    className={`table__cell ${c.className} align-middle`}
                                                >
                                                    {r[c.accessor]}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default Score;
