import React, { useEffect, useState } from "react";
import { OverlayTrigger, Table } from "react-bootstrap";
import * as Icons from "react-bootstrap-icons";
import "../../../styles/scoreboard.scss";
import PlayerGolfers from "./PlayerGolfers";

const Scoreboard = ({ players, height }) => {
    const [sortedPlayerData, setSortedPlayerData] = useState([]);

    const COLUMNS = [
        {
            title: "#",
            accessor: "place",
            className: "",
        },
        {
            title: "Player",
            accessor: "player",
            className: "table__cell--player",
        },
        {
            title: "Team Score to Par",
            accessor: "teamScoreToPar",
            className: "",
        },
        {
            title: "Total Points",
            accessor: "totalPoints",
            className: "table__cell--total-points",
        },
        {
            title: "Points Back",
            accessor: "pointsBack",
            className: "",
        },
    ];

    useEffect(() => {
        if (players !== null) {
            let updatedSortedPlayerData = [];

            // HashMap to store players by score
            let leaderboard = new Map();
            players?.forEach((player) => {
                let points = 0;
                let scoreToPar = 0;
                player.golfers.forEach((golfer) => {
                    points += golfer.totalPoints;
                    scoreToPar += golfer.scoreToPar;
                });

                player.scoreToPar = scoreToPar;

                if (!leaderboard.has(points)) {
                    leaderboard.set(points, []);
                }
                leaderboard.get(points).push(player);
            });

            // ordered list by scores
            let orderedPoints = Array.from(leaderboard.keys());
            orderedPoints.sort((a, b) => a - b);

            // calculate placements
            let playerCount = 0;
            let firstPlacePoints = orderedPoints[0];
            orderedPoints.forEach((score) => {
                let place = playerCount + 1;

                leaderboard.get(score).forEach((p) => {
                    let player = {};

                    let playerRowData = {
                        place: place,
                        player: p.firstName + " " + p.lastName,
                        teamScoreToPar: p.scoreToPar,
                        totalPoints: score,
                        pointsBack: score - firstPlacePoints,
                    };

                    player.playerData = p;
                    player.rowData = playerRowData;

                    playerCount++;

                    updatedSortedPlayerData.push(player);
                });
            });

            setSortedPlayerData(updatedSortedPlayerData);
        }
    }, [players]);

    return (
        <div>
            <div className="scoreboard__scoreboard" style={{ height: height }}>
                <Table className="scoreboard__table">
                    <thead>
                        <tr className="table__row--header">
                            {COLUMNS.map((c) => {
                                return <th key={c.accessor}>{c.title}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedPlayerData.map((r) => {
                            return (
                                <tr>
                                    {COLUMNS.map((c) => {
                                        return (
                                            <td className={`${c.className}`}>
                                                <span>
                                                    {r["rowData"][c.accessor]}
                                                </span>
                                                {c.accessor === "player" && (
                                                    <OverlayTrigger
                                                        overlay={
                                                            <div className="player-golfers">
                                                                <PlayerGolfers
                                                                    golfers={
                                                                        r[
                                                                            "playerData"
                                                                        ][
                                                                            "golfers"
                                                                        ]
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                        placement="top"
                                                    >
                                                        <Icons.Person
                                                            style={{
                                                                paddingLeft: 10,
                                                                height: 22,
                                                                width: 22,
                                                            }}
                                                        />
                                                    </OverlayTrigger>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};
export default Scoreboard;
